require('./src/global.css')

const addScript = url => {
  const script = document.createElement('script')
  script.src = url
  document.getElementsByTagName(`body`)[0].appendChild(script)
}

// eslint-disable-next-line no-unused-vars
exports.onRouteUpdate = s => {
  addScript(`//cdn.callrail.com/companies/956414502/b28ddf8424bc43fb5a03/12/swap.js`)
  addScript(process.env.GATSBY_BIRDEYE_REVIEW_URL)
}
